import React, { useState } from 'react'
import Address from './Address';
import Profile from './Profile';
import Upload from './Upload';
import { Link } from 'react-router-dom';
import Qualification from './Qualification';
import Login from './Login';
import Logout from './Logout';
import Contact from './Contact';
import { Helmet } from 'react-helmet';
//import Razorpay from "http://checkout.razorpay.com/v1/checkout.js"



import Pwschange from './Pwschange';

const Payment = () => {
  

  const [status,upstatus] = useState(null);
  const [paystatus,upaystatus] = useState(false);





  const paymentHandler = async (event) => {

   /* 

    const amount = 65000;
    const currency = 'INR';
    const receiptId = '1234567890';
    const respo = await fetch("http://88.222.245.149:8080/paystatus", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        uname : localStorage.getItem('login')

      }
      
    })

const a1 = await respo.json();
console.log(a1);
if(a1 === "Ok" )
{


    const response = await fetch("http://88.222.245.149:8080"+ "/order", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        amount,
        currency,
        receipt: receiptId
      })
    })

      const order = await response.json();
      console.log('order', order);


      var option = {
        key:"",
        amount,
        currency,
        name:"Web Codder",
        description: "Live Transaction",
        image:"http://i.ibb.co/5Y3m33n/test.png",
        order_id:order.id,
        handler: async function(response) {
          
          const body = {...response,}

          const validateResponse = await fetch('"http://88.222.245.149:8080"+ "/validate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            username:localStorage.getItem('login')
          },
          body: JSON.stringify(body)

          })

          const jsonResponse = await validateResponse.json();

          console.log('jsonResponse', jsonResponse);
          upstatus(jsonResponse.paymentId);
        },
        prefill: {
          name: localStorage.getItem('name'), 
          email: localStorage.getItem('login'),
          contact: localStorage.getItem('mobile'), 
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      }

       var rzp1 = new  Razorpay(option);
      rzp1.on("payment.failed", function(response) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      })

      rzp1.open(); 
      event.preventDefault();
    }
    else{
      upaystatus(true);
    }
 */
    }




return (
<div className='float-container'>
<Helmet>
                <script src="http://checkout.razorpay.com/v1/checkout.js"
                        type="text/javascript"
                />
 </Helmet>
<div className='float-child' >
      
<ul style={{ display: 'grid', justifyContent: 'center' }}>
      <Link to="/profile" element={localStorage.getItem("login") !== "" ? <Profile /> : <Login />}  >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>

            <b>Personel Infomation </b>
          </button>
        </Link>
        <Link to="/Contact" element={localStorage.getItem("login") !== null ? <Contact /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Contact Details </b>
          </button>
        </Link>


          <Link to="/Address" element={localStorage.getItem("login") !== null ? <Address /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Address </b>
          </button>
        </Link>
        <Link to="/Qualification" element={localStorage.getItem("login") !== null ? <Qualification /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Enter Qualification </b>
          </button>
        </Link>




        <Link to="/Upload" element={localStorage.getItem("login") !== null ? <Upload /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Photo Upload </b>
          </button></Link>
        <Link to="/Payment" element={localStorage.getItem("login") !== null ? <Payment /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b>Fees Payment </b>  </button></Link>
            <Link to="/Pwschange" element={localStorage.getItem("login") !== null ? <Pwschange /> : <Login />}>
<button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
<b>Change Password </b>  </button></Link>

            <Link to="/Logout" element={localStorage.getItem("login") !== null ? <Logout /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b>Logout </b>  </button></Link>

        
        
          </ul>


      
      

    </div>


    <div style={{margin:"40px"}}>
    
      <h1> Pay Examination Fees</h1>
      <button className="btn btn-secondary btn-lg" style={{ padding: '18px', margin: '10px', width: "250px" }}  onClick={paymentHandler}>Pay Now</button>
{paystatus===true?<h1 style={{color:"green"}}>Fees Paid </h1>:""}


    </div>
    </div>
  )
}

export default Payment
