import React from 'react'
import { BrowserRouter as Router, Routes, Route,Link,Navgator } from 'react-router-dom';
import User from './User';
import Login from './Login';
import Profile from './Profile';
import Contact from './Contact';
import Address from './Address';
import Upload from './Upload';
import Payment from './Payment';
import Dinied from './Dinied';
import Qualification from './Qualification';
import Navbar from './Navbar';
import { useEffect } from 'react';
import Result from "./Result";
import Logout from './Logout';
import Notverified from './Notverified';
import Navbar2 from './Navbar2';
import Pwschange from './Pwschange';

const Login1 = () => {
  


  return (
    <div>
<Navbar2/>
      <Router>

               
            <Routes>
                 
            <Route index exact path="/" element={<Login/>} />
              <Route exact path="/User" element={localStorage.getItem("login")!==null?<User/>:<Login/>} />
              <Route exact path="/profile" element={localStorage.getItem("login")!==null?<Profile />:<Login/>} />
              <Route exact path="/Contact" element={localStorage.getItem("login")!==null?<Contact />:<Login/>} />
              <Route path="/Address" element={localStorage.getItem("login")!==null?<Address />:<Login/>} />
              <Route path="/Upload" element={localStorage.getItem("login")!==null?<Upload />:<Login/>} />
              <Route path="/Payment" element={localStorage.getItem("login")!==null?<Payment />:<Login/>} />
              <Route path="/Qualification" element={localStorage.getItem("login")!==null?<Qualification />:<Login/>} />
              <Route path="/Pwschange" element={localStorage.getItem("login")!==null?<Pwschange />:<Login/>} />
              <Route path="/Logout" element={localStorage.getItem("login")!==null?<Logout />:<Login/>} />
              <Route path="/Result" element={<Result/>}/>
              <Route path="/Notverified/:email/:key" element={<Notverified/>}/>
            </Routes>
       

      
          </Router>


        </div>
        )
}



        export default Login1
