import React, { useState, useRef } from 'react'
import Address from './Address';
import Payment from './Payment';
import Profile from './Profile';
import Upload from './Upload';
import { Link, useNavigate } from 'react-router-dom';
import Qualification from './Qualification';
import validator from 'validator';
import Login from './Login';
import Logout from './Logout';
import Pwschange from './Pwschange';

const Contact = () => {


const navigate2 = useNavigate();

const mobileref = useRef(null);
const emailref = useRef(null);





  const subcontact = async () => {
    if (document.getElementById("mobileid").length <10 || document.getElementById("mobileid").length >10) {
      alert("Please Enter Mobile No");
      mobileref.current.focus();
    } else
      if (!validator.isEmail(document.getElementById("emailid").value)) {
        alert("Please Enter valid Email id");
        emailref.current.focus();
      }
      else {
        const response = await fetch("http://88.222.245.149:8080"+ "/contact", {
          method: 'post',
          headers: {
            username: localStorage.getItem("login"),
            mobile: document.getElementById("mobileid").value,
            email: document.getElementById("emailid").value

          }


        })
        const t = await response.json();
        
        if (t.Status == 424) {
          localStorage.setItem('mobile',document.getElementById("mobileid").value)
          navigate2("/Address");
        }


      }
  } 
  

  const a = async () => {
    const response = await fetch("http://88.222.245.149:8080"+ "/findcontact", {
      method: 'post',
      headers: {
        uname: localStorage.getItem("login")
     }
    })
    const okdata = await response.json();
    if (okdata.complete=="Yes") {
      document.getElementById("emailid").value = okdata.email;
      document.getElementById("mobileid").value = okdata.mobile;
      

    }

  }


const b = () =>{
  a();
}




  return (
    <div className='float-container'>
      {b()}
      <div className='float-child' >
      <ul style={{ display: 'grid', justifyContent: 'center' }}>
      <Link to="/profile" element={localStorage.getItem("login") !== "" ? <Profile /> : <Login />}  >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>

            <b>Personel Infomation </b>
          </button>
        </Link>
        <Link to="/Contact" element={localStorage.getItem("login") !== null ? <Contact /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Contact Details </b>
          </button>
        </Link>


          <Link to="/Address" element={localStorage.getItem("login") !== null ? <Address /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Address </b>
          </button>
        </Link>
        <Link to="/Qualification" element={localStorage.getItem("login") !== null ? <Qualification /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Enter Qualification </b>
          </button>
        </Link>




        <Link to="/Upload" element={localStorage.getItem("login") !== null ? <Upload /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Photo Upload </b>
          </button></Link>
        <Link to="/Payment" element={localStorage.getItem("login") !== null ? <Payment /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b>Fees Payment </b>  </button></Link>
            <Link to="/Pwschange" element={localStorage.getItem("login") !== null ? <Pwschange /> : <Login />}>
<button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
<b>Change Password </b>  </button></Link>

            <Link to="/Logout" element={localStorage.getItem("login") !== null ? <Logout /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b>Logout </b>  </button></Link>

        
        
          </ul>


      
      </div>
      <div style={{ margin: "40px" }}>

        <h1>Enter Contact Details </h1>
        
        <table>
          <tr>
            <td style={{ padding: "20px" }} >

              <input name="mobile" id="mobileid" ref={mobileref} type="number" placeholder='Enter Mobile No' className="form-control"  ></input>
            </td>
          </tr>
          <tr>
            <td style={{ padding: "20px" }} >

              <input name="email" id="emailid" ref={emailref} type="text" placeholder='Enter Alternate email ID' className="form-control"  ></input>
            </td>
          </tr>
          <tr><div className="submit-container">

            <div className="submit" onClick={subcontact}> submit
            </div>

          </div>
          </tr>
        </table>
      </div>
    </div>

  )
}

export default Contact
