import React from 'react'
import Address from './Address';
import Contact from './Contact';
import Payment from './Payment';
import Profile from './Profile';
import Upload from './Upload';
import { Link } from 'react-router-dom';
import Qualification from './Qualification';
import Login from './Login';
import Logout from './Logout';
import Pwschange from './Pwschange';

const Navbar = () => {
  return (
    <div className='float-container'>
    <div className='float-child' >
    <ul style={{ display: 'grid', justifyContent: 'center' }}>
      <Link to="/profile" element={localStorage.getItem("login") !== "" ? <Profile /> : <Login />}  >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>

            <b>Personel Infomation </b>
          </button>
        </Link>
        <Link to="/Contact" element={localStorage.getItem("login") !== null ? <Contact /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Contact Details </b>
          </button>
        </Link>


          <Link to="/Address" element={localStorage.getItem("login") !== null ? <Address /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Address </b>
          </button>
        </Link>
        <Link to="/Qualification" element={localStorage.getItem("login") !== null ? <Qualification /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Enter Qualification </b>
          </button>
        </Link>




        <Link to="/Upload" element={localStorage.getItem("login") !== null ? <Upload /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Photo Upload </b>
          </button></Link>
        <Link to="/Payment" element={localStorage.getItem("login") !== null ? <Payment /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b>Fees Payment </b>  </button></Link>
            <Link to="/Pwschange" element={localStorage.getItem("login") !== null ? <Pwschange /> : <Login />}>
<button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
<b>Change Password </b>  </button></Link>

            <Link to="/Logout" element={localStorage.getItem("login") !== null ? <Logout /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b>Logout </b>  </button></Link>

        
        
          </ul>


      
</div>
</div>
  )
}
export default Navbar