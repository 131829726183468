import React, { useState, useRef } from 'react'

import Address from './Address';
import Contact from './Contact';
import Payment from './Payment';
import Login from './Login';
import Upload from './Upload';
import { Link, useNavigate } from 'react-router-dom';
import Qualification from './Qualification';
import Logout from './Logout';
import Pwschange from './Pwschange';
const Profile = () => {
  const firstnameref = useRef(null);
  const middlenameref = useRef(null);
  const lastnameref = useRef(null);
  const mothernameref = useRef(null);
  const genderref = useRef(null);
  const dobref = useRef(null);
  


  const a = async () => {
    const response = await fetch("http://88.222.245.149:8080"+ "/findprofil", {
      method: 'post',
      headers: {
        uname: localStorage.getItem("login")



      }
    })
    const okdata = await response.json();
    if (okdata.complete=="Yes") {
      document.getElementById("fname").value = okdata.firstname;
      document.getElementById("mname").value = okdata.middlename;
      document.getElementById("lname").value = okdata.lastname;
      document.getElementById("moname").value = okdata.mothername;
      if (okdata.gender === "Male") {

        document.getElementById("male").checked = true;
      }
      else if (okdata.gender === "Female") {
        document.getElementById("female").checked = true;
      }
      else if (okdata.gender === "Trans") {
        document.getElementById("trans").checked = true;
      }
      document.getElementById("dob").value = okdata.dob;

    }

  }

  const navigate1 = useNavigate();


  const subprofile = async (e) => {
    var ele = document.getElementsByName('Radio');
    var i;
    var gen1;
     for (i = 0; i < ele.length; i++) {
         if (ele[i].checked)
             {gen1 = ele[i].value}
   
   
     
   }
   
   
   
   
    if (document.getElementById("fname").value === "") {
      alert("Please Enter First Name");
      firstnameref.current.focus();
    }
    else if (document.getElementById("mname").value === "") {
      alert("Please Enter Middle Name");
      middlenameref.current.focus();
    }
    else if (document.getElementById("lname").value === "") {
      alert("Please Enter Last Name");
      lastnameref.current.focus();

    }
    else if (document.getElementById("moname").value === "") {
      alert("Please Enter Mother Name");
      mothernameref.current.focus();

    }
    else if (gen1 === undefined) {
      alert("Please Select Gender");
      genderref.current.focus();

    }
    else if (document.getElementById("dob").value === "") {
      alert("Please Select Date of Birth");
      dobref.current.focus();

    }
    else {
      
      const response = await fetch("http://88.222.245.149:8080"+ "/Profil", {
        method: 'post',
        headers: {
          username: localStorage.getItem("login"),
          firstname: document.getElementById("fname").value,
          middlename: document.getElementById("mname").value,
          lastname: document.getElementById("lname").value,
          mothername: document.getElementById("moname").value,
          gender: gen1,
          dob: document.getElementById("dob").value

        }

      })
      const t = await response.json();
      console.log(t);
      if (t.Status === "424") {
        localStorage.setItem('name',  document.getElementById("fname").value + " " + document.getElementById("mname").value + " " + document.getElementById("lname").value )
        document.getElementById("name1").innerHTML = document.getElementById("fname").value;
        navigate1("/Contact");
      }
    }



  }

  
  const b = () => {
    a();
  }

  return (

    <div className='float-container'>
     <div className='float-child' >


        {b()}
        <ul style={{ display: 'grid', justifyContent: 'center' }}>
      <Link to="/profile" element={localStorage.getItem("login") !== "" ? <Profile /> : <Login />}  >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>

            <b>Personel Infomation </b>
          </button>
        </Link>
        <Link to="/Contact" element={localStorage.getItem("login") !== null ? <Contact /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Contact Details </b>
          </button>
        </Link>


          <Link to="/Address" element={localStorage.getItem("login") !== null ? <Address /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Address </b>
          </button>
        </Link>
        <Link to="/Qualification" element={localStorage.getItem("login") !== null ? <Qualification /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Enter Qualification </b>
          </button>
        </Link>




        <Link to="/Upload" element={localStorage.getItem("login") !== null ? <Upload /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Photo Upload </b>
          </button></Link>
        <Link to="/Payment" element={localStorage.getItem("login") !== null ? <Payment /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b>Fees Payment </b>  </button></Link>
            <Link to="/Pwschange" element={localStorage.getItem("login") !== null ? <Pwschange /> : <Login />}>
<button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
<b>Change Password </b>  </button></Link>

            <Link to="/Logout" element={localStorage.getItem("login") !== null ? <Logout /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b>Logout </b>  </button></Link>

        
        
          </ul>

</div>
     
      <div style={{ marginLeft: "40px" }} id="d1">
        <form name="frm">
          <table>
            <tr>
              <td style={{ padding: "20px" }} >
                
                <input type="text" ref={firstnameref} placeholder='Enter First Name'  name="firstname" id="fname" className="form-control"  ></input>
              </td>
              <td style={{ padding: "20px" }}>

                <input type="text" ref={middlenameref}  name="middlename" placeholder='Enter Middle  Name' id="mname" className="form-control"  ></input>
              </td>
              <td style={{ padding: "20px" }}>

                <input type="text" ref={lastnameref} name="lastname"  placeholder='Enter Last Name' id="lname" className="form-control"  ></input>
              </td>
            </tr>
            <tr>
              <td style={{ padding: "20px" }}>

                <input ref={mothernameref} name="mothername" type="text"  placeholder='Enter Mother Name' id="moname" className="form-control"  ></input>
              </td>
            </tr>
            <tr>
              <td style={{ padding: "20px" }}>

                Male <input type="radio" ref={genderref} name="Radio" Value="Male" id="male" ></input>
              </td>
              <td style={{ padding: "20px" }}>
                Female <input type="radio" name="Radio" Value="Female" id='female' ></input>
              </td>
              <td style={{ padding: "20px" }}>
                Transgender <input type="radio" name="Radio" Value="Trans" id='trans' ></input>
              </td>
            </tr>
            <tr>
              <td style={{ padding: "20px" }}>Select Date of Birth</td>
              <td><input type='date' ref={dobref} className='form-control' id="dob" name="DOB" ></input></td>
            </tr>
          </table>
          <div className="submit-container">

            <div className="submit" onClick={subprofile}> submit
            </div>

          </div>
        </form>


      </div>
    </div>
  )
}
export default Profile
