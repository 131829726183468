import React from 'react'
import { useParams } from 'react-router-dom';

function Notverified  (props) {
  const {email,key } = useParams();
  const t1 = async () =>{
    if(email!=="" && key!=="")
    {
      
      const res = await fetch("http://88.222.245.149:8080"+ "/verify", {
        method: 'post',
        headers: {
          email:email,
          key: key
           
        }});
      


      return(<h1> {await res.json()}</h1>);
    }
    else
    {
      return(<h1> In Valid Link</h1>);
    }

  }



   return (
    <div>
      {email ==="" || key === ""?<h1>Invalid link </h1> : <p><h1><p>{key}</p> </h1>
      <h1><p>{email}</p> </h1> </p>}
      
      <h2 >{t1()}</h2> 

    </div>
  )
}

export default Notverified
