import React, { useRef } from 'react'
import { useState } from 'react';
import Contact from './Contact';
import Payment from './Payment';
import Profile from './Profile';
import Upload from './Upload';
import { Link,useNavigate } from 'react-router-dom';
import Qualification from './Qualification';
import Login from './Login';
import Pwschange from './Pwschange';
import Logout from './Logout';





const Address = () => {
        const navigate2 = useNavigate();
        const stateref = useRef(null);
        const districtref = useRef(null);
        const tehsilref = useRef(null);
        const cityref = useRef(null);
        const pinref = useRef(null);
        const hnoref = useRef(null);
        const bnameref = useRef(null);
        const add1ref = useRef(null);
        const add2ref = useRef(null);
        const add3ref = useRef(null);
        const add4ref = useRef(null);
        const [district, setdistrict] = useState([]);
        const [tehsil, settehsil] = useState([]);
        const [data10,setData10]=useState({
                statename:"" ,district:"",tehsil:"",city:"",pincode:"",hno:"",bname:"",add1:"",add2:"",add3:"",add4:""
        })
        const [statename1,setStatename1] = useState("Select State");
        const [district1,setDistrict1] = useState("Select District");
        const [tehsil1,setTehsil1] = useState("Select Tehsil");
        
        const a = async () => {
                const response = await fetch("http://88.222.245.149:8080"+ "/findaddress", {
                  method: 'post',
                  headers: {
                    uname: localStorage.getItem("login")
                 }
                })
                const okdata = await response.json();
                if (okdata.complete=="Yes") {
                  const   list = document.getElementById("s1");
                  var option = document.createElement('option');
                  option.text = option.value = okdata.statename;
                  list.add(option,0);
                  list.selectedIndex = 0;
                  const   list2 = document.getElementById("dd1");
                  var option1 = document.createElement('option');
                  option1.text = option.value = okdata.district;
                  list2.add(option1,0);
                  list2.selectedIndex = 0;
                  const   list3 = document.getElementById("t1");
                  var option3 = document.createElement('option');
                  option3.text = option.value = okdata.tehsil;
                  list3.add(option3,0);
                  list3.selectedIndex = 0;
                                   
                  document.getElementById("city").value = okdata.city;
                  document.getElementById("pin").value = okdata.pincode;
                  document.getElementById("hnoid").value = okdata.hno;
                  document.getElementById("bnameid").value = okdata.bname;
                  document.getElementById("add1id").value = okdata.add1;
                  document.getElementById("add2id").value = okdata.add2;
                  document.getElementById("add3id").value = okdata.add3;
                  document.getElementById("add4id").value = okdata.add4;
            
                }
            
              }
            

const b = () =>{
        a();
}
        
        
        
        
        const subaddress = async () => {
                
         if(document.getElementById("s1").value==="Select State")
         {
                alert("please select state");
                stateref.current.focus();
         }
         else if(document.getElementById("dd1")==="Select District")
         {
                alert("Please select Ditrict");
                districtref.current.focus();

         } else if(document.getElementById("t1").value==="Select Tehsil")
         {
                alert("Please select Tehsil ");
                tehsilref.current.focus();

         }else if(document.getElementById("city")===""){
                alert("Please Enter City or Village ");
                cityref.current.focus();

         }else if(document.getElementById("pin").value===""){
                alert("Please Enter Pincode ");
                pinref.current.focus();

         } else if(document.getElementById("hnoid").value===""){
                alert("Please Enter House No ");
                hnoref.current.focus();

         }    else if(document.getElementById("bnameid")===""){
                alert("Please Enter Building Name ");
                bnameref.current.focus();

         }else if(document.getElementById("add1id").value===""){
                alert("Please Enter Address Line 1 ");
                add1ref.current.focus();

         }
         else if(document.getElementById("add2id").value===""){
                alert("Please Enter Address Line 2 ");
                add2ref.current.focus();

         }
         else if(document.getElementById("add3id").value===""){
                alert("Please Enter Address Line 3 ");
                add3ref.current.focus();

         }else if(document.getElementById("add1id").value===""){
                alert("Please Enter Address Line 4 ");
                add4ref.current.focus();

         }else{

        const response = await fetch("http://88.222.245.149:8080"+ "/address", {
                        method: 'post',
                        headers: {
                          username: localStorage.getItem("login"),
                           
    statename:document.getElementById("s1").value,
    district:document.getElementById("dd1").value,
    tehsil:document.getElementById("t1").value,
    city:document.getElementById("city").value,
    pincode:document.getElementById("pin").value,
    hno:document.getElementById("hnoid").value,
    bname:document.getElementById("bnameid").value,
    add1:document.getElementById("add1id").value,
    add2:document.getElementById("add2id").value,
    add3:document.getElementById("add3id").value,
    add4:document.getElementById("add4id").value
                           }
                           
              
                      })
                      const t = await response.json();
                      
                      if (t.Status == 424) {
                        navigate2("/Qualification");
                      }
              
              
         }

        }
        
        
        
        const f1 = async (e) => {
                
               
                const response = await fetch("http://88.222.245.149:8080"+ "/demo1", {
                        method: 'GET',
                        headers: {
                                state1: e.target.value
                        }
                })
                const data = await response.json();
                setdistrict(data);
                        }
               
                const f2 = async (e)=> {
                        const response = await fetch("http://88.222.245.149:8080"+ "/demo2", {
                                method: 'GET',
                                headers: {
                                        district1: e.target.value
                                }
                        })
                        const data = await response.json();
                        settehsil(data);
                        console.log(data);
     

                } 
               
        
       





        return (
                <div className='float-container'>
                        <div className='float-child' >

                        <ul style={{ display: 'grid', justifyContent: 'center' }}>
      <Link to="/profile" element={localStorage.getItem("login") !== "" ? <Profile /> : <Login />}  >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>

            <b>Personel Infomation </b>
          </button>
        </Link>
        <Link to="/Contact" element={localStorage.getItem("login") !== null ? <Contact /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Contact Details </b>
          </button>
        </Link>


          <Link to="/Address" element={localStorage.getItem("login") !== null ? <Address /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Address </b>
          </button>
        </Link>
        <Link to="/Qualification" element={localStorage.getItem("login") !== null ? <Qualification /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Enter Qualification </b>
          </button>
        </Link>




        <Link to="/Upload" element={localStorage.getItem("login") !== null ? <Upload /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Photo Upload </b>
          </button></Link>
        <Link to="/Payment" element={localStorage.getItem("login") !== null ? <Payment /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b>Fees Payment </b>  </button></Link>
            <Link to="/Pwschange" element={localStorage.getItem("login") !== null ? <Pwschange /> : <Login />}>
<button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
<b>Change Password </b>  </button></Link>

            <Link to="/Logout" element={localStorage.getItem("login") !== null ? <Logout /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b>Logout </b>  </button></Link>

        
        
          </ul>





                        </div>


                        <div style={{ margin: "40px" }}>
                                <h1>Enter Corropsondance Address </h1>
                                {b()}
                                <table cellPadding={5}>
                                        <tr><td>Select State</td>
                                                <td>
                                                        <select className='form-control' name="statename" ref={stateref} id="s1" onChange={f1}>
                                                                <option value="Select State"> Select State</option>
                                                                <option value="Andhra Pradesh">Andhra Pradesh </option>
                                                                <option value="Arunachal Pradesh">Arunachal Pradesh
                                                                </option>
                                                                <option value="Assam">Assam
                                                                </option>
                                                                <option value="Bihar
">Bihar
                                                                </option>
                                                                <option value="Chhattisgarh
">Chhattisgarh
                                                                </option>
                                                                <option value="Goa
">Goa
                                                                </option>
                                                                <option value="Gujarat
">Gujarat
                                                                </option>
                                                                <option value="Haryana
">Haryana
                                                                </option>
                                                                <option value="Himachal Pradesh
">Himachal Pradesh
                                                                </option>
                                                                <option value="Jharkhand
">Jharkhand
                                                                </option>
                                                                <option value="Karnataka
">Karnataka
                                                                </option>
                                                                <option value="Kerala
">Kerala
                                                                </option>
                                                                <option value="Maharashtra
">Maharashtra
                                                                </option>
                                                                <option value="Madhya Pradesh
"> Madhya Pradesh
                                                                </option>
                                                                <option value="Manipur
">Manipur
                                                                </option>
                                                                <option value="Meghalaya
">Meghalaya
                                                                </option>
                                                                <option value="Mizoram
">Mizoram
                                                                </option>
                                                                <option value="Nagaland
">Nagaland
                                                                </option>
                                                                <option value="Odisha
">Odisha
                                                                </option>
                                                                <option value="Punjab
">Punjab
                                                                </option>
                                                                <option value="Rajasthan
">Rajasthan
                                                                </option>
                                                                <option value="Sikkim
">Sikkim
                                                                </option>
                                                                <option value="Tamil Nadu
"> Tamil Nadu
                                                                </option>
                                                                <option value="Tripura
">Tripura
                                                                </option>
                                                                <option value="Telangana
"> Telangana
                                                                </option>
                                                                <option value="Uttar Pradesh
">Uttar Pradesh
                                                                </option>
                                                                <option value="Uttarakhand
">Uttarakhand
                                                                </option>
                                                                <option value="West Bengal
">West Bengal
                                                                </option>
                                                                <option value="Andaman & Nicobar (UT)
">Andaman & Nicobar (UT)
                                                                </option>

                                                                <option value="Chandigarh (UT)
"> Chandigarh (UT)
                                                                </option>
                                                                <option value="Dadra & Nagar Haveli and Daman & Diu (UT)
">Dadra & Nagar Haveli and Daman & Diu (UT)
                                                                </option>
                                                                <option value="Delhi [National Capital Territory (NCT)]
">Delhi [National Capital Territory (NCT)]
                                                                </option>
                                                                <option value="Jammu & Kashmir (UT)
">Jammu & Kashmir (UT)
                                                                </option>
                                                                <option value="Ladakh (UT)
">Ladakh (UT)
                                                                </option>
                                                                <option value="Lakshadweep (UT)
">Lakshadweep (UT)
                                                                </option>
                                                                <option value="Puducherry (UT)
">Puducherry (UT)
                                                                </option>
                                                        </select>


                                                </td>
                                                <td>
                                                        Select District
                                                </td>
                                                <td>
                                                        <select name="district" className='form-control' ref={districtref} onChange={f2} id="dd1" >
                                                                <option>Select District</option>
                                                                {district.map((district) => (<option>{district.district}</option>))}
                                                        </select> 



                                                </td>
                                        </tr>
                                        <tr>
                                                <td>Select Tehsil</td>
                                                <td>
                                                        <select name="Tehsil" className='form-control' ref={tehsilref}  id="t1">
                                                                <option>Select Tehsil</option>
                                                                {tehsil.map((tehsil) => <>{tehsil.tehsil.map((sub) => <option>{sub}</option>)}</>)}
                                                        </select>

                                                </td>
                                        </tr>
                                        <tr>
                                                <td>
                                                        <label>Enter City or Village </label>
                                                </td>
                                                <td>
                                                        <input type='text'  ref={cityref} id="city" ></input>
                                                </td>
                                                <td>
                                                        <label>Pin Code </label>
                                                </td>
                                                <td>
                                                        <input type='Number'  ref={pinref} id="pin"></input>
                                                </td> 
                                        </tr>
                                        <tr>
                                                <td>
                                                        <label>Enter House No </label>
                                                </td>
                                                <td>
                                                        <input type='Number' name="hno" ref={hnoref} id="hnoid" ></input>
                                                </td> 
                                                <td>
                                                        <label>Enter Building Name </label>
                                                </td>
                                                <td>
                                                        <input type='text' name="bname" ref={bnameref} id="bnameid"></input>
                                                </td> 
                                        </tr>
                                        <tr>
                                                <td>
                                                        <label>Enter Address Line1 </label>
                                                </td>
                                                <td>
                                                        <input type='text' name="add1" ref={add1ref} id="add1id" ></input>
                                                </td> 
                                                <td>
                                                        <label>Enter Address Line2 </label>
                                                </td>
                                                <td>
                                                        <input type='text' name="add2" ref={add2ref} id="add2id" ></input>
                                                </td>
                                        </tr>
                                        <tr>
                                                <td>
                                                        <label>Enter Address Line3 </label>
                                                </td>
                                                <td>
                                                        <input type='text' name="add3" ref={add3ref} id="add3id"></input>
                                                </td>
                                                <td>
                                                        <label>Enter Address Line4 </label>
                                                </td>
                                                <td>
                                                        <input type='text' name="add4" ref={add4ref} id="add4id"></input>
                                                </td>
                                        </tr>
<tr>
<div className="submit-container">

<div className="submit" onClick={subaddress} > submit
</div>

</div>
</tr>


                                </table>
                        </div>
                </div>
        )
}

export default Address
