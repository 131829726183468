
import Navbar from './Navbar';




const User = () => {



  return (
    <div className='float-container'>
      
      <Navbar />
    </div>
  )
}

export default User
