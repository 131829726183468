import logo from './logo.svg';
import './App.css';


import Login1 from './Component/Login1';

function App() {
  return (
    <div className="App">

  <Login1/>
          </div>
  );
}

export default App;
