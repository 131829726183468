import React from 'react'
import { useState } from 'react';
import logo2 from "./Profile.png";
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import pdf from "../notification.pdf"




const Login = () => {

const [res,setRes] = useState(null);
const [data2,setData2] = useState(false);
const [valid,setValid] = useState(true);
const [user1,setUser1] = useState(false);
const navigate1 = useNavigate();
  const [ldata, setldata] = useState({
    lemail: "", lpass: "", cpass: ""
  });
  const [Resend,setResend] = useState(null);
  let name, value;
  const handle = (e) => {

    name = e.target.name;
    value = e.target.value;

    setldata({ ...ldata, [name]: value });
setUser1(false);
setData2(false);
setRes(null);
  }


  const submitreq = async (e) => {
if( validator.isEmail(ldata.lemail)  && ldata.lpass.length >= 6 )
{
    const response = await fetch("http://88.222.245.149:8080"+ "/login", {
      method: 'post',
      rejectUnauthorized: false,
      headers: {
        uname: ldata.lemail,
        pass: ldata.lpass,


      }
    })
    const data = await response.json();
   
    if (data.message !== "Invalid Credential") {
      if(data.message==="Email Not Verified")
      {
        setData2(true);
      }
      else{
      const username = data;
      const resfname = await fetch("http://88.222.245.149:8080"+ "/findprofil", {
        method: 'post',
        headers: {
          uname: ldata.lemail,
        
  
  
        }
      })
      const fdata = await resfname.json();
      document.getElementById("n1").innerHTML = username;
      if(fdata){
        if(fdata.firstname!=null)
          {
          document.getElementById("name1").innerHTML = fdata.firstname;
          }
          else
          {
            document.getElementById("name1").innerHTML = "Guest";
          }
    


        }
      var path = "";
        try{
        const respic = await fetch("http://88.222.245.149:8080"+ "/download", {
        method: 'post',
        headers: {
          uname: ldata.lemail,
        
  
        }
      })
       path = await respic.json();
    }
    catch (e){
      console.log("error 2 " + e);
    }
    alert("ashish");     
    if(path!=""){
      document.getElementById('logo2').src =  "http://88.222.245.149:8080"+ "/static/" + path
}
else
{
  document.getElementById('logo2').src = (logo2);
}

document.getElementById('logo2').style.display = "inline";
    document.getElementById('log').style.display = "inline";
      localStorage.setItem('login',username)
      navigate1("/User");
setValid(true);}
    }
    else{
      setValid(false);

    }
     } 
  }
  const reg = async (e)=>{
    if(ldata.lpass === ldata.cpass && validator.isEmail(ldata.lemail) && ldata.lpass.length >= 6  )
{  const response = await fetch("http://88.222.245.149:8080"+ "/Register", {
    method: 'post',
    headers: {
      username: ldata.lemail,
      password: ldata.lpass
    
    }


  
});
try{
const res = await fetch("http://88.222.245.149:8080"+ "/mail", {
  method: 'post',
  headers: {
    gemail: ldata.lemail,
     
  }



});}
catch(e){
  console.log("error " + e);
}




const result = await response.json(); 

console.log("result : " + result.Status);

if(result.Status==="424"){

  

  navigate1("/Result");

}
else {
  setUser1(true);
}  
}


}

const lostpws = async (e)=>{
  if(validator.isEmail(ldata.lemail))
  {
    const res = await fetch("http://88.222.245.149:8080"+ "/lostpws", {
      method: 'post',
      headers: {
        gemail: ldata.lemail,
        
      }
    
    
    
    });
   
    
    setRes(await res.json());
    console.log(await res.json());

  }
  else{
    alert("Please Enter Email ID");

  }
}

const resend = async (e)=> {

  const res = await fetch("http://88.222.245.149:8080"+ "/resend", {
    method: 'post',
    headers: {
      gemail: ldata.lemail,
       
    }
  
  
  
  });
  
setResend(await res.json());



}
  const [action, setAction] = useState("Register");
  const [flagemail, setFlagemail] = useState("false");
  const [flagpass, setFlagpass] = useState("false");
  const [flagcpass, setFlagcpass] = useState("false");
  return (
    
    <div id="div1" className='float-container2'>
      
        <div className='containerone'>
      <div className="l">
      <p ><h2>For information on the application process for the probationary officer position, please click on the link provided in the detailed advertisement. 
        <br></br><a href={pdf} target='_Blank'>Click here for detailed advertisment  </a>
       </h2> </p>
       </div>
       </div>
      <div className='container'>
        <div className='header'>
          <div className='text'>{action}</div>
          <div className="underline"></div>
        </div>
        <div className="submit-container">
          <div className={action === "Login" ? "submit gray" : "submit"} onClick={() => { setAction("Register");setData2(false); }}> SignUP
          </div>
          <div className={action === "Register" ? "submit gray" : "submit"} onClick={() => { setAction("Login") }}> Login
          </div>
        </div>

        <div className="inputs">
          {(!validator.isEmail(ldata.lemail) && flagemail === "true") ? <div className='input1'>Please Enter Valid Email</div> : <div></div>}
          {(validator.isEmail(ldata.lemail) && user1 === true) ? <div className='input1'>Email already Register</div> : <div></div>}
          <div className="input">

            <img src="" alt=""></img>
            <input type='email' name="lemail" placeholder='Enter Email' onChange={handle} onBlur={() => setFlagemail("true")} />
          </div>

        </div>
        <div className="inputs">


          {(ldata.lpass.length < 6 && flagpass === "true") ? <div className='input1'>Please Enter Minumum six character long Password</div> : <div></div>}

          <div className="input">

            <img src="" alt=""></img>
            <input type='password' name="lpass" placeholder='Enter Password' onChange={handle} onBlur={() => setFlagpass("true")} />
          </div>
        </div>
        {action === "Register" ?
          <div className="inputs">
            {(ldata.lpass !== ldata.cpass && flagcpass === "true") ? <div className='input1'>Confirm password does not match</div> : <div></div>}
            <div className="input">
              <img src="" alt=""></img>
              <input type='password' name="cpass" placeholder='Confirm Password' onChange={handle} onBlur={() => setFlagcpass("true")} />
            </div>
          </div> : <div></div>

        }
        {action === "Register" ? <div></div> :
          <div className="forgot-password">Lost Password <span onClick={lostpws}>Click Here</span></div>
            }
            { action !== "Register" && res!=null? <div className='input1'> {res}</div>:""}
        {valid===false?  <div className='input1'>Invalid Credential</div> :<div></div>  }
        <div className="submit-container">
        
          <div className="submit" onClick={action==="Register"?reg:submitreq}> submit
               
          </div>
        </div>
        {action==='Login' && data2===true ? <div>
  <h3> Email Not Verified ....to Re-send Verificaiotn email </h3>
  <div className="submit22" onClick={resend}> Resend
  </div> 
  </div>:"" }
  <div style={{color:"green"}}>
<h3>
{Resend!=null?Resend:""}  
</h3>
</div>
      </div>
    </div>
   
  )
}

export default Login
