import React  from 'react'

import Address from './Address';
import Contact from './Contact';
import Payment from './Payment';
import Profile from './Profile';
import Login from './Login';
import { Link } from 'react-router-dom';
import Qualification from './Qualification';
import { useState } from 'react';
import Logout from './Logout';
import  axios  from 'axios';
import Pwschange from './Pwschange';
const Upload = () => {
  const [file, setFile] = useState(null);
  const [server,Setserver] = useState(null); 
 const [t,setT] = useState(false);
  const a = async () => {
    const response = await fetch("http://88.222.245.149:8080"+ "/download", {
      method: 'post',
      headers: {
        uname: localStorage.getItem("login")



      }
    })
  const path = await response.json();
    
  if(path){
    Setserver(path);
    document.getElementById('logo2').src =  "http://88.222.245.149:8080"+ "/static/" + server ;
    


  } 
  }

const b= () => {
  a();
}

  const uploadfile = async () => {
    if(file== null)
      {alert("Please select file less than 100kb size")}
      else{  
      
    
    
    const formData = new FormData()
    formData.append('file', file)
    formData.append('uname',localStorage.getItem('login'))
    axios.post('"http://88.222.245.149:8080"+ "/upload',formData )
   .then( res => {
    
    if(res.data==="Ok"){
    
      setT(true);
    }
    
    })
  .catch(er => console.log(er))
  b();

      }
  
  }
  



  return (
    <div className='float-container'>
      <div className='float-child' >
      {b()}
      <ul style={{ display: 'grid', justifyContent: 'center' }}>
      <Link to="/profile" element={localStorage.getItem("login") !== "" ? <Profile /> : <Login />}  >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>

            <b>Personel Infomation </b>
          </button>
        </Link>
        <Link to="/Contact" element={localStorage.getItem("login") !== null ? <Contact /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Contact Details </b>
          </button>
        </Link>


          <Link to="/Address" element={localStorage.getItem("login") !== null ? <Address /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Address </b>
          </button>
        </Link>
        <Link to="/Qualification" element={localStorage.getItem("login") !== null ? <Qualification /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Enter Qualification </b>
          </button>
        </Link>




        <Link to="/Upload" element={localStorage.getItem("login") !== null ? <Upload /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Photo Upload </b>
          </button></Link>
        <Link to="/Payment" element={localStorage.getItem("login") !== null ? <Payment /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b>Fees Payment </b>  </button></Link>
            <Link to="/Pwschange" element={localStorage.getItem("login") !== null ? <Pwschange /> : <Login />}>
<button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
<b>Change Password </b>  </button></Link>

            <Link to="/Logout" element={localStorage.getItem("login") !== null ? <Logout /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b>Logout </b>  </button></Link>

        
        
          </ul>




      </div>



      <div style={{ margin: "40px" }}>



  <div className='cemter1'>
    <h1>Upload Cadidate Photo  of 100kb Size</h1>

    <form>
      
      <div className='center1'>
        <img
          alt="Upload Photo of 100kb size"
          id="img1"
          width={"250px"}
          src={  file != null ? URL.createObjectURL(file) : server != null ?  "http://88.222.245.149:8080"+ "/static/" + server : "" }
        /> </div> 


        <input type="file" className='submitfile' onChange={(e) => 
          {
          if(e.target.files[0]!=null){
            if(e.target.files[0].size > 102400)
          {alert("Selected File Size is Greater than 100kb please select file size less than 100kb")
            setFile(null);
          }
          else{  
            
           setT(false); 
            Setserver(null);
            
          setFile(e.target.files[0]);
          
          
        
      
      }} }
          }/>
          {t?<div style={{color:"green"}}>
    <h1>Photo is Uploaded successfully </h1>
  </div> : ""} 


          <div className="submit-container">

            <div className="up-btn" onClick={uploadfile}> Upload            </div>
            <button className='up-btn' onClick={() => setFile(null)}>Remove</button>
          


          </div> 
        
        
        
      </form>
      </div>
  

    
    
  </div>
      
    </div>
  )
}

export default Upload
