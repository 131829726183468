import React from 'react'

import { useState } from 'react';
import Contact from './Contact';
import Payment from './Payment';
import Profile from './Profile';
import Upload from './Upload';
import { Link } from 'react-router-dom';
import Qualification from './Qualification';
import Login from './Login';
import Address from './Address';
import Logout from './Logout';


function Pwschange() {
  const [l,setL] = useState(null);

    const [ldata, setldata] = useState({
        oldpass:"", lpass: "", cpass: ""
      });
      let name, value;
      const handle = (e) => {
    
        name = e.target.name;
        value = e.target.value;
    
        setldata({ ...ldata, [name]: value });
      }
    


const subpws = async () => {
  var resfname=null;
  if(ldata.oldpass===""){
    alert("Enter Old Password");
  }
  else if (ldata.lpass!==ldata.cpass)
  {
    alert("Confirm Password and New Paasword is not matching");
  }
  else {
    const response = await fetch("http://88.222.245.149:8080"+ "/login", {
        method: 'post',
        headers: {
          uname: localStorage.getItem('login'),
          pass: ldata.oldpass
  
  
        }
      })
      const data = await response.json();
      if (data.message !== "Invalid Credential") {
                
            if(ldata.lpass.localeCompare(ldata.cpass)===0)
                { 
                  alert(ldata.lpass +" "+ ldata.cpass);
        resfname = await fetch("http://88.222.245.149:8080"+ "/chngpws", {
          method: 'post',
          headers: {
            username: localStorage.getItem('login'),
            newpass : ldata.lpass
             
    
          }
        }) }
      const reply = await resfname.json();
      alert(reply);
      if(reply==="Password Change Succesfully") 
     {
      setL(reply);
     }}
     else{
      setL("Please enter correct Old Password");
     }
           
        }
      }




    return (
    <div className='float-container'>
    <div className='float-child' >

    <ul style={{ display: 'grid', justifyContent: 'center' }}>
      <Link to="/profile" element={localStorage.getItem("login") !== "" ? <Profile /> : <Login />}  >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>

            <b>Personel Infomation </b>
          </button>
        </Link>
        <Link to="/Contact" element={localStorage.getItem("login") !== null ? <Contact /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Contact Details </b>
          </button>
        </Link>


          <Link to="/Address" element={localStorage.getItem("login") !== null ? <Address /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Address </b>
          </button>
        </Link>
        <Link to="/Qualification" element={localStorage.getItem("login") !== null ? <Qualification /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Enter Qualification </b>
          </button>
        </Link>




        <Link to="/Upload" element={localStorage.getItem("login") !== null ? <Upload /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Photo Upload </b>
          </button></Link>
        <Link to="/Payment" element={localStorage.getItem("login") !== null ? <Payment /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b>Fees Payment </b>  </button></Link>
            <Link to="/Pwschange" element={localStorage.getItem("login") !== null ? <Pwschange /> : <Login />}>
<button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
<b>Change Password </b>  </button></Link>

            <Link to="/Logout" element={localStorage.getItem("login") !== null ? <Logout /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b>Logout </b>  </button></Link>

        
        
          </ul>


    </div>
    <div style={{ margin: "40px" }}>

<h1>Change Passsword </h1>

<table>
  <tr>
    <td style={{ padding: "20px" }} >
Current  Passsword : </td><td style={{ padding: "20px" }}>
      <input name="oldpass" id="oldpass"  type="text" placeholder='Enter Old Password' className="form-control" onChange={handle} ></input>
    </td>
  </tr>
  <tr>
    <td style={{ padding: "20px" }} >
      New Passsword :   </td><td style={{ padding: "20px" }}>
      <input name="lpass" id="lpass"  type="text" placeholder='Enter New Password' className="form-control" onChange={handle} ></input>
    </td>
  </tr>
  <tr>
    <td style={{ padding: "20px" }} >
      Confirm Passsword : </td><td style={{ padding: "20px" }}>
       
           <input name="cpass" id="cpass"  type="text" placeholder='Enter Confirm Password' className="form-control" onChange={handle}  ></input>
    </td>
  </tr>


  <tr> <td> {l==="Password Change Succesfully"?<div><h3 style={{color:"green"}}> {l}</h3></div>:<div><h3 style={{color:"red"}}> {l}</h3></div>}</td> </tr>
  <tr><td  align='center'>
      </td><td>
    <div className="submit-container">

    <div className="submit" onClick={subpws}> submit
    </div>

  </div>
  </td> 
  </tr>
</table>
</div>





      
   </div> 
  )
} 

export default Pwschange
