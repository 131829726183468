import React, { useRef, useState } from 'react'
import Address from './Address';
import Contact from './Contact';
import Payment from './Payment';
import Profile from './Profile';
import Upload from './Upload';
import { Link, json, useNavigate } from 'react-router-dom';
import Login from './Login';
import Logout from './Logout';
import Pwschange from './Pwschange';





const Qualification = () => {
  const navigate2 = useNavigate();
  const Board10ref= useRef(null);
  const year10ref = useRef(null);
  const perc10ref = useRef(null);
  const Board12ref = useRef(null);
  const year12ref = useRef(null);
  const perc12ref = useRef(null);
  const Board15ref  = useRef(null);
  const year15ref = useRef(null);
  const perc15ref = useRef(null);
  
  const [quli,setQuli]= useState({
Board10:"",
year10:"",
perc10:"",
Board12:"",
year12:"",
perc12:"",
Board15:"",
year15:"",
perc15:"",
Board17:"",
year17:"",
perc17:""
 })
let name,value;
const handle=(e)=>
{
name=e.target.name;
value=e.target.value;
setQuli({...quli,[name]:value})

} 
const a = async () => {
  const response = await fetch("http://88.222.245.149:8080"+ "/findquali", {
    method: 'post',
    headers: {
      uname: localStorage.getItem("login")
   }
  })
  const okdata = await response.json();
  if (okdata.complete==="Yes") {
document.getElementById("Board10").value = okdata.Board10;
document.getElementById("year10").value = okdata.year10;
document.getElementById("perc10").value = okdata.perc10;
document.getElementById("Board12").value = okdata.Board12;
document.getElementById("year12").value = okdata.year12;
document.getElementById("perc12").value = okdata.perc12;
document.getElementById("Board15").value = okdata.Board15;
document.getElementById("year15").value = okdata.year15;
document.getElementById("perc15").value = okdata.perc15;
document.getElementById("Board17").value = okdata.Board17;
document.getElementById("year17").value = okdata.year17;
document.getElementById("perc17").value = okdata.perc17;



  }
}
const b = () =>  {
  a();
}





 const subquali = async()=>{
  if(document.getElementById("Board10").value===""){

  alert("Pleaese Enter 10th Board Name ");
Board10ref.current.focus();
} else  if(document.getElementById("year10").value===""){

  alert("Pleaese Enter 10th passing Year ");
year10ref.current.focus();
}else  if(document.getElementById("perc10").value===""){

  alert("Pleaese Enter 10th Percentage ");
perc10ref.current.focus();
 } else  if(document.getElementById("Board12").value===""){

  alert("Pleaese Enter 12th / diploma Board Name ");
Board12ref.current.focus();
} else  if(document.getElementById("year12").value===""){

  alert("Pleaese Enter 12th passing Year ");
year12ref.current.focus();
} else  if(document.getElementById("perc12").value===""){

  alert("Pleaese Enter 12th Percentage ");
perc12ref.current.focus();
} else  if(document.getElementById("Board15").value===""){

  alert("Pleaese Enter Graduation University ");
Board15ref.current.focus();
} else  if(document.getElementById("year15").value===""){

  alert("Pleaese Enter Graduation Year ");
year15ref.current.focus();
} else  if(document.getElementById("perc15").value===""){

  alert("Pleaese Enter Graduation Percentage ");
perc15ref.current.focus();
}
else {

  const response = await fetch("http://88.222.245.149:8080"+ "/qulif", {
                  method: 'post',
                  headers: { 
                    username: localStorage.getItem("login"),
                    board101:document.getElementById("Board10").value,
                    year10:document.getElementById("year10").value,
                    perc10:document.getElementById("perc10").value,
                    board12:document.getElementById("Board12").value,
                    year12:document.getElementById("year12").value,
                    perc12:document.getElementById("perc12").value,
                    board15:document.getElementById("Board15").value,
                    year15:document.getElementById("year15").value,
                    perc15:document.getElementById("perc15").value,
                    board17:document.getElementById("Board17").value,
                    year17:document.getElementById("year17").value,
                    perc17:document.getElementById("perc17").value
                                        
                  }  
        
})
                const t = await response.json();
                alert(quli.Board15 + " "+ quli.Board17 + " "+ quli.Board10 + " "+ quli.Board12);
                if (t.Status == 424) {
                  navigate2("/Upload");
                }
        
        
   }



 }
 
  return (
    <div className='float-container'>
      <div className='float-child' >
      {b()}
      
      <ul style={{ display: 'grid', justifyContent: 'center' }}>
      <Link to="/profile" element={localStorage.getItem("login") !== "" ? <Profile /> : <Login />}  >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>

            <b>Personel Infomation </b>
          </button>
        </Link>
        <Link to="/Contact" element={localStorage.getItem("login") !== null ? <Contact /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Contact Details </b>
          </button>
        </Link>


          <Link to="/Address" element={localStorage.getItem("login") !== null ? <Address /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Address </b>
          </button>
        </Link>
        <Link to="/Qualification" element={localStorage.getItem("login") !== null ? <Qualification /> : <Login />} >
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Enter Qualification </b>
          </button>
        </Link>




        <Link to="/Upload" element={localStorage.getItem("login") !== null ? <Upload /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b> Photo Upload </b>
          </button></Link>
        <Link to="/Payment" element={localStorage.getItem("login") !== null ? <Payment /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b>Fees Payment </b>  </button></Link>
            <Link to="/Pwschange" element={localStorage.getItem("login") !== null ? <Pwschange /> : <Login />}>
<button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
<b>Change Password </b>  </button></Link>

            <Link to="/Logout" element={localStorage.getItem("login") !== null ? <Logout /> : <Login />}>
          <button type="button" className="btn btn-secondary btn-lg" style={{ padding: '14px', margin: '8px', width: "200px" }}>
            <b>Logout </b>  </button></Link>

        
        
          </ul>


</div>



        <div style={{ margin: "40px" }}>
          
          <table cellPadding={10} border={5}>
            <b>
              <tr> <th colSpan={4} align='center'> <h1> Enter Qualification</h1></th></tr>
              <tr>
                <td> Course Name </td>
                <td> Board / University </td>
                <td> Passing Year </td>
                <td> Final %tage </td>
              </tr>
              <tr>
                <td> SSC </td>
                <td>
                  <input type='text' id="Board10" ref={Board10ref} className="form-control"></input>   </td>
                <td>
                  <input type="Number" id="year10" ref={year10ref} className="form-control"></input>   </td>
                <td>
                  <input type="Number" id="perc10" ref={perc10ref}  className="form-control"></input>   </td>
              </tr>
              <tr>
                <td> HSC / Diploma </td>
                <td> <input type='text' id="Board12" ref={Board12ref}  className="form-control"></input>   </td>
                <td> <input type="Number" id="year12" ref={year12ref}  className="form-control"></input>   </td>
                <td> <input type="Number" id="perc12" ref={perc12ref}  className="form-control"></input>   </td>
              </tr> 
              <tr>
                <td> Graduation </td>
                <td> <input type='text' id="Board15" ref={Board15ref}  className="form-control"></input>   </td>
                <td> <input type="Number" id="year15" ref={year15ref}  className="form-control"></input>   </td>
                <td> <input type="Number" id="perc15" ref={perc15ref} className="form-control"></input>   </td>
              </tr>
              <tr>
                <td> Post Graduation  </td>
                <td> <input type='text' id="Board17" className="form-control"></input>   </td>
                <td> <input type="Number" id="year17"  className="form-control"></input>   </td>
                <td> <input type="Number" id="perc17" className="form-control"></input>   </td>
              </tr>

            </b>
            <tr >
              <td colSpan="4" style={{textAlign:"center"}} >
      
                <div className="submit" onClick={subquali} > submit
        
        </div>
       </td>
            </tr>
          </table>

        </div>
      </div>
    
  )
}

export default Qualification
