import React from 'react'
import logo from './RBC.jpg';
import logo3 from './Profile.png';


const Navbar2 = () => {
  const a="";
  return (
<>
<nav className='navbar1' role='navigation'>

<div className="navbar-left">
  <img src={logo} className="circular_image1"></img>
    <a href="/" className="logo">
      Royal Bank of Canada 
    </a>
  </div>
  

</nav>


<nav className="navbar" role="navigation">
  
  <div className="navbar-center">
  
  <ul className="nav-links">
       <li id="n1">
    <a href="/" className="logo">
    -
    </a>
    </li>
    <li id="h1" > 
    -   
    </li>
     
      
    </ul>
    </div> 
  <div className="navbar-center">
    <ul className="nav-links">
    <li>
       
     <img id="logo2" src={logo3} style={{display:"none"}} className="circular_image"></img> 

    </li>
    <li  > Hello   
        </li>
        <li id="name1" > -  
        </li>
    

       
         <li id="log" style={{display:"none"}} >
             
                </li>
        
        </ul></div>  
 
</nav>
</>    



    
       
      )
}

export default Navbar2
