import React from 'react'


function Result() {
  return (
    <div>
    <div className='main-container'>
      <h3> <p>The email verification link should have been sent to your registered email address associated with your account. Please check your inbox and ensure that you also look in the spam or junk folder, as it may have been filtered there. If you still cannot find it, you can try requesting the verification link again or contact customer support on support@rbc-career.com for further assistance. </p> </h3>
      </div><div style={{color:"green" } } >
    <h3><center><a href="http://localhost:3000">Click here for Login Page</a></center></h3>
    </div></div>
  )
}

export default Result
