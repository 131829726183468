import React from 'react'


function Logout() {
  return (
    <div>
     <h1> You are successfully Logout !</h1>
     <h2> go to login page <a href="http://localhost:3000">Clicl Here</a></h2>
   {localStorage.setItem('login',null)}
   {document.getElementById("logo2").style.display="none"}
   

    </div>
  )
}

export default Logout
